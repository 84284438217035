//firebase.js

/*
import store from './store'
if (store.getters['auth/check']) {
    console.log("1");
}
*/

import firebase from 'firebase/compat/app';
import axios from 'axios';

import { getMessaging, getToken, isSupported} from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyA5LRbiOg0tXmXao7V80Q71wjUixM_ntbE",
  authDomain: "mangolola.firebaseapp.com",
  projectId: "mangolola",
  storageBucket: "mangolola.appspot.com",
  messagingSenderId: "717252216415",
  appId: "1:717252216415:web:2bc9b1f986f00c6d66bdea",
  measurementId: "G-X4LHZ65VC2",
  requestPermissions: false,
};


firebase.initializeApp(firebaseConfig)
let messaging;
const isIOSSafari = !!window.navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
const isIosChrome = navigator.userAgent.indexOf('CriOS') >= 0;

if ('serviceWorker' in navigator && isSupported() && !isIOSSafari && !isIosChrome){
    messaging = getMessaging();
    //console.log("isSupported",isSupported());
    //console.log("isIOSSafari",isIOSSafari);
   // console.log('Notification',Notification.permission);
    Promise.resolve(Notification.requestPermission()).then(function(permission) {
        if (permission === 'denied') {
            console.log('requestPermission ', permission);
            //
        } else if (permission === 'granted') {
            console.log('requestPermission ', permission);
            fetchToken();
        }
    });

}


function fetchToken(){


    getToken(messaging, { vapidKey: 'BBjEvSF8LUwKBmiF-JYO0ScGZDBluM-s-WEL4h8HWEfmb4dNe-_LtzSaOkoFFDZxMkU4iC2wZUxhYJv7gw5Jtqo' }).then((currentToken) => {
        if (currentToken) {
           // console.log('Token', currentToken);
        // Send the token to your server and update the UI if necessary

            axios.post("/api/fcm-token",{
            _method:"PATCH",
            token: currentToken
            }).then(({data})=>{
                console.log('fcm:',data.success)
            }).catch(({response:{data}})=>{
                console.error(data)
            })

        // ...
        } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });

}

export default messaging;







import * as types from '../mutation-types'
import axios from 'axios'
/*
export default {
  namespaced: true,
}
*/
export const state = {
    feed: [],
    meta: {},
    links: {},
    filter: [],
    unavailability: [],
    loading: true,
    feedRequest: null,
  }

  export const getters = {
    getFeedData (state) {
      return state.feed
    },
    getFeedLinks (state) {
      return state.links
    },
    getFeedMeta (state) {
      return state.meta
    },
    getFilter (state) {
      return state.filter
    },
    getUnavailability (state) {
      return state.unavailability
    },
    getFeedLoading (state) {
      return state.loading
    },
  }

  export const mutations= {
    [types.SET_LOADING_DATA] (state, { status }) {
      state.loading = status
    },
    [types.SET_FILTER_DATA] (state, { filter }) {
      state.filter = filter.data
    },
    [types.SET_FEED_DATA] (state, { feed }) {
      state.feed = feed.data
      state.meta = feed.meta
      state.links = feed.links

    },
      [types.SET_FEED_REQUEST] () {
          state.feedRequest = null;
      },
    [types.SET_UNAIVABILITY_DATA] (state, { unavailability }) {
      state.unavailability = unavailability.data
    }
  }

/*let controller;

const event = document.querySelector('.load');
const abort = document.querySelector('.abort');

event.addEventListener('click', actions.fetchFeed);
abort.addEventListener('click', () => {
  if (controller) {
    controller.abort();
    console.log('Operation canceled due to new request.');
  }
});*/

  export const actions = {

    async fetchUnavailability ({ commit }, params) {
      try {
        const { data } = await axios.get('/api/unavailability', { params })
        commit(types.SET_UNAIVABILITY_DATA, { unavailability: data })
      } catch (e) {
        console.log(e);
      }
    },
    async fetchFeed ({ commit, state }, params) {

      if (state.feedRequest) {
        state.feedRequest.cancel();
      }

      const CancelToken = axios.CancelToken;
      state.feedRequest = CancelToken.source();

      commit(types.SET_LOADING_DATA, { status: true });
      //if (typeof cancelToken != typeof undefined) {
        //cancelToken.cancel("Operation canceled due to new request.")
      //}
      //cancelToken = axios.CancelToken.source()
        axios.get('api/feed-simple', {params, cancelToken: state.feedRequest.token},)
           .then(({data})  => {
            commit(types.SET_FEED_DATA, { feed: data })
           })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('Request canceled', error.message);
            }
           }).finally(() => {
            commit(types.SET_LOADING_DATA, {status: false})
            state.feedRequest = null;
          }

           )


    },

  }



function page (path) {
  return () => import(/* webpackChunkName: '' */ `../pages/${path}`).then(m => m.default || m)
}

export default [
  {
    path: '/',
    name: 'welcome',
    component: page('landing/HomePage.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
   //  component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
   //component: page('AboutPage.vue')
   component: page('landing/AboutUs.vue')
  },
  {
    path: '/for-vendors',
    name: 'vendorsintro',
    component: page('landing/VendorsIntroPage.vue')
  },
  {
    path: '/for-couples',
    name: 'usersintro',
    component: page('landing/UsersIntroPage.vue')
  },
  {
    path: '/investors-relations',
    name: 'investorsRelations',
    component: page('landing/Investors.vue')
  },
  {
    path: '/legal',
    name: 'legal',
    component: page('landing/Legal.vue')
  },
  {
    path: '/our-founder',
    name: 'founder',
    component: page('landing/Founder.vue')
  },
  {
    path: '/how-to-use-mango-lola',
    name: 'how-to-use-couples',
    component: page('landing/HowToUseCouples.vue')
  },
  {
    path: '/the-first-five-things-you-need-to-do',
    name: 'first-five-things',
    component: page('landing/FirstFiveThings.vue')
  },
  {
    path: '/wedding-planning-timeline',
    name: 'wedding-timeline',
    component: page('landing/WeddingTimeline.vue')
  },
  {
    path: '/legal/subpage/',
    name: 'legal.subpage',
    component: page('landing/LegalSubpage.vue'),
    children: [
      {
        path: '/privacy-policy',
        name: 'privacy.policy',
        component: page('landing/Privacy-policyPage.vue')
      },
      {
        path: '/terms-and-conditions',
        name: 'terms.and.conditions',
        component: page('landing/Terms-and-Conditions-ML-Page.vue')
      },
    ]
  },
    /*
  {
    path: '/vendor/:id/terms-and-conditions',
    name: 'vendor.terms.and.conditions',
    component: page('landing/Terms-and-Conditions-Vendor-Page.vue')
  },
     */
  /*{
    path: '/promotions',
    name: 'promotions',
    component: page('landing/PromotionsPage.vue')
  },*/
  {
    path: '/giveaway/:id',
    name: 'giveaway.show',
    component: page('landing/GiveawayDetailPage.vue')
  },
  {
    path: '/promotions/:id',
    name: 'promotions.show',
    component: page('landing/PromotionDetailPage.vue')
  },
  {
    path: '/newsletters',
    name: 'newsletters',
    component: page('landing/NewsLetterPage.vue')
  },
  {
    path: '/faq',
    name: 'faq',
    component: page('landing/Faq.vue')
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: page('landing/Marketing.vue')
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: page('landing/Jobs.vue')
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: page('landing/ContactUs.vue')
  },
  {
    path: '/fcm',
    name: 'fcm',
    component: page('test/fcmPage.vue')
  },
  {
    path: '/places',
    name: 'places',
    component: page('PlacesPage.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: page('landing/HomePage.vue')
  },
  {
    path: '/feed',
    name: 'feed',
    component: page('FeedPage.vue')},
  {
    path: '/login',
    name: 'login',
    component: page('auth/login.vue')},
  {
    path: '/logout',
    name: 'logout',
    component: page('auth/logout.vue')},
  {
    path: '/register',
    name: 'register',
    component: page('auth/register.vue')},
  {
    path: '/password/reset',
    name: 'password.request',
    component: page('auth/password/email.vue')},
  {
    path: '/password/reset/:token',
    name: 'password.reset',
    component: page('auth/password/reset.vue')},
  {
    path: '/email/verify/:id',
    name: 'verification.verify',
    component: page('auth/verification/verify.vue')},
  {
    path: '/account/activate/:code',
    name: 'account.active',
    component: page('auth/activation/activate.vue')
  },
  {
    path: '/email/resend',
    name: 'verification.resend',
    component: page('auth/verification/resend.vue')},
  {
    path: '/event/invitations/accept/:id',
    name: 'event.invitations.accept',
    component: page('auth/verification/eventInvitation.vue')},
  {
      path: '/invitations/email/:id',
      name: 'invitations.verify',
      component: page('auth/verification/invitation.vue')},
  {
        path: '/unsubscribes/:id',
        name: 'unsubscribes.store',
        component: page('auth/unsubscribe/unsubscribes.vue')},
  {
    path: '/address',
    component: page('address/AddressPage.vue'),
    name: 'address'
  },
  {
    path: '/address/create',
    component: page('address/CreateAddressPage.vue'),
    name: 'address.create'
  },
  {
    path: '/address/:id/edit',
    component: page('address/EditAddressPage.vue'),
    name: 'address.edit'
  },
  {
    path: '/address/:id',
    component: page('address/ShowAddressPage.vue'),
    name: 'address.show',
    props: true
  },
  {
    path: '/vendors/dashboard',
    name: 'vendors.dashboard',
    component: page('vendors/DashboardPage.vue')
  },
  {
    path: '/vendors/tutorials',
    name: 'vendors.faq.videos',
    component: page('vendors/FaqVideoPage.vue')
  },
  {
    path: '/vendors/dashboard/subpage/',
    name: 'vendors.dashboard.subpage',
    component: page('vendors/DashboardSubpage.vue'),
    children: [
      {
        path: '/vendors/showcaseWork',
        name: 'vendors.showcaseWork',
        component: page('vendors/showcasework/ShowcaseWorkPage.vue')
      },
      {
        path: '/vendors/calendar',
        name: 'vendors.calendar',
        component: page('vendors/calendar/CalendarPage.vue'),
      },
      {
        path: '/vendor/quotes',
        name: 'vendor.quotes',
        component: page('vendors/quotesPage.vue')
      },
      {
        path: '/vendor/bookings',
        name: 'vendor.bookings',
        component: page('vendors/bookings/bookingsPage.vue')
      },
      {
        path: '/vendors/ratings',
        name: 'vendors.ratings',
        component: page('vendors/ratings/ratingCollectorPage.vue')
      },
      {
        path: '/vendor/payments',
        name: 'vendor.payments',
        component: page('vendors/payments/PaymentsPage.vue'),
        children: [
          {
            path: '/vendor/payment-methods',
            name: 'vendor.payment-methods',
            component: page('vendors/payments/PaymentMethodsPage.vue')
          },
          {
            path: '/vendor/stripe/reauth',
            name: 'vendor.stripe.reauth',
            component: page('vendors/payments/stripe/reauthPage.vue')
          },
        ]
      },
      {
        path: '/vendor/offers',
        name: 'vendor.offers',
        component: page('vendors/offers/OfferPage.vue')
      },
        /*
      {
        path: '/vendor/terms',
        name: 'vendor.terms',
        component: page('vendors/TermsPage.vue')
      },
      */
      {
        path: '/vendors/settings',
        name: 'vendors.settings',
        component: page('vendors/settingsPage.vue')
      },
    ]
  },
  {
    path: '/vendors/calendar/settings',
    name: 'vendors.calendar.settings',
    component: page('vendors/calendar/CalendarSettingsPage.vue')
  },
  {
    path: '/vendors/my-company',
    name: 'vendors.myCompany',
    component: page('vendors/MyCompanyPage.vue'),
    meta: {
      layout: 'RedNavbarLayout'
    }
  },
  {
    path: '/vendors/profile',
    name: 'vendors.profile',
    component: page('vendors/ProfilePage.vue'),
    meta: {
      layout: 'RedNavbarLayout'
    }
  },
  {
    path: '/vendors/showcaseWork/create',
    name: 'vendors.showcaseWork.create',
    component: page('vendors/showcasework/ShowcaseWorkManagePage.vue')
  },
  {
    path: '/vendors/showcaseWork/:id/edit',
    name: 'vendors.showcaseWork.edit',
    component: page('vendors/showcasework/ShowcaseWorkManagePage.vue')
  },
  {
    path: '/vendors/company',
    name: 'vendors.company',
    component: page('vendors/company/companyPage.vue')
  },
  {
    path: '/vendor/package/create',
    name: 'vendor.package.create',
    component: page('vendors/offers/package/PackageManagePage.vue')
  },
  {
    path: '/vendor/package/:id/edit',
    name: 'vendor.package.edit',
    component: page('vendors/offers/package/PackageManagePage.vue')
  },
  {
    path: '/vendor/product/create',
    name: 'vendor.product.create',
    component: page('vendors/offers/product/ProductManagePage.vue')
  },
  {
    path: '/vendor/product/:id/edit',
    name: 'vendor.product.edit',
    component: page('vendors/offers/product/ProductManagePage.vue')
  },
  {
    path: '/vendor/add-ons',
    name: 'vendor.add-ons',
    component: page('vendors/offers/add-on/AddonPage.vue')
  },
  {
    path: '/vendor/add-on/create',
    name: 'vendor.add-on.create',
    component: page('vendors/offers/add-on/AddonManagePage.vue')
  },
  {
    path: '/vendor/add-on/:id/edit',
    name: 'vendor.add-on.edit',
    component: page('vendors/offers/add-on/AddonManagePage.vue')
  },
  {
    path: '/vendor/fees',
    name: 'vendor.fees',
    component: page('vendors/offers/fees/FeesPage.vue')
  },
  {
    path: '/vendor/fees/create',
    name: 'vendor.fees.create',
    component: page('vendors/offers/fees/FeesManagePage.vue')
  },
  {
    path: '/vendor/fees/:id/edit',
    name: 'vendor.fees.edit',
    component: page('vendors/offers/fees/FeesManagePage.vue')
  },
  {
    path: '/reviews/:id',
    name: 'reviews.show',
    component: page('rating/ReviewsPage.vue')
  },
  {
    path: '/rating/:email/:id',
    name: 'publicReview.create',
    component: page('rating/PublicReview.vue')
  },
  {
    path: '/vendor/onboarding-old',
    name: 'vendor.onboarding.old',
    component: page('OnBoardingVendorPage.vue')
  },
  {
    path: '/vendor/onboarding',
    name: 'vendor.onboarding',
    component: page('OnBoardingVendorPageNew.vue')
  },
  {
    path: '/showcasework/:id',
    component: page('ShowcaseWorkPage.vue'),
    name: 'showcasework.show',
    props: true
  },
  {
    path: '/showcasework/details/:id',
    component: page('ShowcaseWorkDetailsPage.vue'),
    name: 'showcasework-details.show',
    props: true
  },
  {
    path: '/showpackage/:id',
    component: page('ShowPackagePage.vue'),
    name: 'package.show',
    props: true
  },
  {
    path: '/user/budget',
    name: 'user.budget',
    component: page('users/budgetPage.vue')
  },
  {
    path: '/user/quotes',
    name: 'user.quotes',
    component: page('users/quotesPage.vue')
  },
  {
    path: '/messages',
    name: 'messages',
    component: page('MessagePage.vue')
  },
  /*
  moved to component ViewBooking
  {
    path: '/vendor/booking/:id',
    name: 'vendor.booking',
    component: page('vendors/bookings/viewBookingPage.vue')
  },
  */
  // Start User routes
  {
    path: '/orders/:id/payment',
    name: 'payment.order',
    component: page('payment/paymentPage.vue')
  },
  /*
  {
    path: '/orders/:id/payment/verify',
    name: 'order.payment.verify',
    component: page('payment/paymentAuthorizePage.vue')
  },
  */
  {
    path: '/orders',
    name: 'orders',
    component: page('users/bookings/bookingsPage.vue')
  },
  {
    path: '/orders/:id',
    name: 'order.show',
    component: page('users/bookings/viewBookingPage.vue')
  },
  {
    path: '/orders/:id/verify',
    name: 'order.show.verify',
    component: page('users/bookings/viewBookingPage.vue')
  },
  {
    path: '/orders/:id/payment/verify',
    name: 'order.show.payment.verify',
    component: page('users/bookings/viewBookingPage.vue')
  },
  {
    path: '/user/myevent',
    name: 'user.myevent',
    component: page('users/myEventPage.vue')
  },
  {
    path: '/user/my-account',
    name: 'user.my-account',
    component: page('users/myAccountPage.vue'),
    meta: {
      layout: 'RedNavbarLayout'
    }
  },
  {
    path: '/user/dashboard',
    name: 'user.dashboard',
    component: page('users/DashboardPage.vue'),
  },
  {
    path: '/user/myvendors',
    name: 'user.myvendors',
    component: page('users/myVendorsPage.vue')
  },
  { path: '*', name: 'notFound', component: page('errors/404.vue') }
]

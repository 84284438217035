
import * as types from '../mutation-types'
import axios from 'axios'
/*
export default {
  namespaced: true,
}
*/
export const state = {
    cartItems: null,
    viewMyCart: false,
    orderStatus: false
  }

  export const getters = {
    getCart(state) {
      return state.cartItems
    },
    getViewMyCart (state) {
      return state.viewMyCart
    },
    getOrderStatus (state) {
      return state.orderStatus
    }     
  }
  
  export const mutations= {
    [types.SET_CART_DATA] (state, { data }) {
      state.cartItems = data
    },
    [types.SET_CART_WINDOW] (state, { data }) {
      state.viewMyCart = data
    },
    [types.SET_ORDER_STATUS] (state, { data }) {
      state.orderStatus = data
    },    
  }

  export const actions = {
    setCartWindow ({commit}, data){
      commit(types.SET_CART_WINDOW, { data: data.cart })
    },
    setOrderStatus ({commit}, data){
      commit(types.SET_ORDER_STATUS, { data: data.status })
    },    
    async fetchCart ({ commit }, params) {
      try {
        const { data } = await axios.get('/api/cart', { params })
        commit(types.SET_CART_DATA,  { data: data.data } )
      } catch (e) {
        console.log(e);
      }
    },
    async updateCartItem ({ commit }, params) {
      try {
        //console.log(params.item);
        let id = params.item.id;
        let item = params.item;

        const { data } = await axios.put('/api/cart-items/'+id, item);
        
        commit(types.SET_CART_DATA, { data: data })
      } catch (e) {
        console.log(e);
      }
    },
    async addCartItem ({ dispatch }, item) {
      try {
        //console.log(item);
        await axios.post('/api/cart-items', item);
        return dispatch('fetchCartItems')
      } catch (e) {
        console.log(e);
      }
    },     
  }

import * as types from '../mutation-types'
import axios from 'axios'
/*
export default {
  namespaced: true,
}
*/
export const state = {
    budgets: null,
    order_total: null,
    budget_total: null
  }

  export const getters = {
    getBudget (state) {
      return state.budgets
    },
    getOrderTotal (state) {
      return state.order_total
    },
    getBudgetTotal (state) {
      return state.budget_total
    }
  }

  export const mutations = {
    [types.SET_USER_BUDGET] (state, { budget }) {
      state.budgets = budget.data
      state.order_total = budget.order_total
      state.budget_total = budget.budget_total
    }
  }

  export const actions = {
    async fetchBudget ({ commit }) {
      try {
        const { data } = await axios.get('/api/budget')
        commit(types.SET_USER_BUDGET, { budget: data })
      } catch (e) {
        console.log(e);
      }
    },
    
  }

import Cookies from 'js-cookie'
import * as types from '../mutation-types'

//const { locale, locales } = window.config

// state
export const state = {
  locale: Cookies.get('locale') || 'en',
  layout: 'app-layout',
  locales: [
      {'name': 'EN', 'value': 'en'},
      {'name': '中文', 'value': 'zh-CN'},
      {'name': 'ES', 'value': 'es'}]
}

// getters
export const getters = {
  locale: state => state.locale,
  locales: state => state.locales,
  layout: state => state.layout
}

// mutations
export const mutations = {
  [types.SET_LOCALE] (state, { locale }) {
    state.locale = locale
  }
}

// actions
export const actions = {
  setLocale ({ commit }, { locale }) {
    commit(types.SET_LOCALE, { locale })

    Cookies.set('locale', locale, { expires: 365 })
  }
}


import Vue from 'vue'

Vue.mixin({
    methods: {
      dateFormat: function (d) {
        
        
        let tf = Boolean(d);
        if(!d && !tf) {
            //console.log("no date",d);
            return null;
        }else{
          
          //d = d.toISOString().split('T')[0];
          //console.log("d",d);
          //console.log("dt",dt);
          var date = new Date(d.toString());
          //console.log("date",date);
          //console.log("test",(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10));
          if(date instanceof Date){ 
            //console.log("instanceof date",date);
            return new Intl.DateTimeFormat('en-US',{ year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }).format(date)
          }else{
            //console.log("no date instance",date);
            return null;
          }
        }
        
      },
    },
  })
 /*
  import Vue from 'vue'

  const MyDateFormat = {
    install(Vue) {
      Vue.prototype.dateFormat = ($d) => {
        return new Intl.DateTimeFormat('en-US',{ year: 'numeric', month: 'short', day: 'numeric' }).format($d)
      }
    },
  }
  Vue.use(MyDateFormat)
 */
import axios from 'axios'
import store from '../store'
import router from '../router'

// Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters['auth/token']
  if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`; 
    /* 
    Ako su razlicite domene BE i FE onda Sanctum ne koristi X-XSRF-TOKEN
    vec zahtjeva da uz request dobije Bearer token.
    */
  }

  return request
  /*
  const locale = store.getters['lang/locale']
  if (locale) {
    request.headers.common['Accept-Language'] = locale
  }
*/
 
});

// Response interceptor
axios.interceptors.response.use(response => response, error => {
  const { status } = error.response

  //console.log('Response interceptor:' + error);

  if (status >= 500) {
      //console.log('Status 500+:' + status);
      router.push({ name: 'welcome' })
  }
  if (status === 401 && store.getters['auth/check']) {
      //console.log('Status 401:' + status);
      //console.log('Auth:' + store.getters['auth/check']);
      store.commit('auth/LOGOUT')
      router.push({ name: 'welcome' })
  }
      //console.log('Status :' + status);
      return Promise.reject(error.response);


})

// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// budget.js
export const SET_USER_BUDGET = 'SET_USER_BUDGET'

// feed
export const SET_FEED_DATA = 'SET_FEED_DATA'
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const SET_UNAIVABILITY_DATA = 'SET_UNAIVABILITY_DATA'
export const SET_LOADING_DATA = 'SET_LOADING_DATA'
export const SET_FEED_REQUEST = 'SET_FEED_REQUEST'

// Seach & Filter
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA'
export const SET_FILTER_PRODUCTS_DATA = 'SET_FILTER_PRODUCTS_DATA'
export const SET_ORDER_DATA = 'SET_ORDER_DATA'
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS'
export const SET_RESET_FILTERS = 'SET_RESET_FILTERS'

// Cart
export const SET_CART_DATA = 'SET_CART_DATA'
export const SET_CART_WINDOW = 'SET_CART_WINDOW'
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'


// Notifications

export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA'

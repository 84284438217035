import Vue from 'vue'
//import Card from './Card'
// import Child from './'
import Spinner from "./Spinner";

import { HasError, AlertError, AlertSuccess } from 'vform'

// Components that are registered globally.
[
  //Card,
  // Child,
  Spinner,
  HasError,
  AlertError,
  AlertSuccess
].forEach(Component => {
  //console.log(Component);
  Vue.component(Component.name, Component)
})

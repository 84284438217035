import axios from 'axios'
import * as types from '../mutation-types'

/*
export default {
  namespaced: true,

  state: {
    searchData: [],
  },

}

  */

 export const state = {
    searchData: [],
  }

  export const getters = {
    getSearchData (state) {
      return state.searchData
    }
  }
  
  export const mutations = {
    [types.SET_SEARCH_DATA] (state, { data }) {
      state.searchData = data
    }
  }

  export const actions = {
    async fetchSearchData ({ commit }, params) {
      console.log("fetch Search data Vuex...");
      console.log(params);
        axios.get('api/search', {params})
           .then(({data})  => {
            commit(types.SET_SEARCH_DATA, { data: data.product })

           })
          .catch(error => {
              console.log('Error loading search data: ' + error)
           });
    }
    
  }

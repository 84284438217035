import * as types from '../mutation-types'

/*
export default {
  namespaced: true,
}
*/

const initFilterState = {
  "filter[name]": null,
  "filter[queryData]": null,
  "filter[availability_between]": null,
  "filter[availability_dates_in]": null,
  "filter[category_in]": null,
  "filter[product_in]": null,
  "filter[countries_in]": null,
  "sort": null,
  "page": 1,
};

export const state = {
  filterProducts: [],
  orderby: null,
  resetFilterFlag: false,
  filterParams: {...initFilterState}
}

export const getters = {
  getFilterPoductsData (state) {
    return state.filterProducts
  },
  getOrderData (state) {
    return state.orderby
  },
  getFilterParams(state){
    return state.filterParams
  },
  getResetFilterFlag(state){
    return state.resetFilterFlag
  }
}

export const mutations = {
  [types.SET_FILTER_PRODUCTS_DATA] (state, { data }) {
    state.filterProducts = data
  },
  [types.SET_FILTER_PARAMS] (state, { data }) {
    state.filterParams = {...state.filterParams, ...data};
    localStorage.setItem('filterParams', JSON.stringify(state.filterParams));
  },
  [types.SET_ORDER_DATA] (state, { data }) {
    state.orderby = data
  },
  [types.SET_RESET_FILTERS] (state, data) {
    state.resetFilterFlag = data
  },
  initFilterStore(state) {
    if (localStorage.getItem('filterParams') && JSON.parse(localStorage.getItem('filterParams'))) {
      const parsedObject = JSON.parse(localStorage.getItem('filterParams'));
      state.filterParams = {...state.filterParams, ...parsedObject};
    } else {
      state.filterParams = {...initFilterState};
    }
  },
}

export const actions = {
  async setFilterProductsData ({ commit }, products) {
    commit(types.SET_FILTER_PRODUCTS_DATA, { data: products })
  },
  async setFilterParams ({ commit }, params) {
    commit(types.SET_FILTER_PARAMS, { data: params })
  },
  async clearFilterParams ({ commit }) {
    commit(types.SET_FILTER_PARAMS, { data: {...initFilterState} })
  },
  async clearFilterProductsData ({ commit }) {
    commit(types.SET_FILTER_PRODUCTS_DATA, { data: [] })
  },
  async setOrderData ({ commit }, order) {
    commit(types.SET_ORDER_DATA, { data: order })
  },
  async clearOrderData ({ commit }) {
    commit(types.SET_ORDER_DATA, { data: null })
  },
  async setResetFilters ({ commit }, data) {
    commit(types.SET_RESET_FILTERS, data)
  },
}

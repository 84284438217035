import store from '../store'

export default (to, from, next) => {
  if (store.getters['auth/user'].vendor === false) {
    next()    
  } else {
    next({ name: 'welcome' })

  }
}


import * as types from '../mutation-types'
import axios from 'axios'
/*
export default {
  namespaced: true,
}
*/
export const state = {
  notifications: [],
  meta: {},
  }

  export const getters = {
    getNotifications (state) {
      return state.notifications
    },
    getMeta (state) {
      return state.meta
    },    
  }
  
  export const mutations= {
    [types.SET_NOTIFICATION_DATA] (state, { notifications }) {
      state.notifications = notifications.data,
      state.meta = notifications.meta
    },
  }

  export const actions = {
    async setNotification ({ commit }, data) {
      //console.log(msg);
      commit(types.SET_NOTIFICATION_DATA, { notifications: data })
    },
    async fetchNotifications({ commit }, params) {
      axios.get('api/notifications', {params})
           .then(({data})  => {
            commit(types.SET_NOTIFICATION_DATA, { notifications: data })
           })
          .catch(error => {
              console.log('Error loading notifications data: ' + error)
           });
      
           
    },
    
  }
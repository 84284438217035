import store from '../store'

export default (to, from, next) => {
  const user = store.getters['auth/user'];
  //const is_it_boarded = store.getters['auth/user'].is_it_boarded;
  //console.log(user);
  if (user.is_activated === true && user.is_it_boarded === true && user.is_it_settings_approved === true) {
    next()
  } else if (user.is_activated && !user.is_it_boarded) {
    next({ name: 'vendor.onboarding' })
  } else {
    next({ name: 'feed' })
  }
}

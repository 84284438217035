import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './plugins'
import './components'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.css'
import './assets/css/icons.css'
import './assets/sass/app.scss'
import "./assets/tailwind.css"

import vuetify from './plugins/vuetify';

// For conversion E.g currency, number, percetentage etc.
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Poper
import { Popper } from 'popper-vue'
import 'popper-vue/dist/popper-vue.css'

Vue.component('Popper', Popper)

// end


import axios from 'axios'

// vue-html-to-paper
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
}

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
// Vue.use(VueHtmlToPaper);

import VueIziToast from 'vue-izitoast'
import 'izitoast/dist/css/iziToast.css';
Vue.use(VueIziToast);

//https://vcalendar.io/
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
Vue.component('v-calendar-io', Calendar)
Vue.component('v-date-picker-io', DatePicker)


//// vee-validat
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
localize('en', en);

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

//import vSelect from 'vselect-component'
//Vue.component('v-select', vSelect)

Vue.config.productionTip = false
Vue.prototype.$http = axios;
axios.defaults.timeout = 60000; // millsec
axios.defaults.withCredentials = true;

Vue.prototype.$appName = process.env.VUE_APP_TITLE;
Vue.prototype.$appNameShort = process.env.VUE_APP_TITLE_SHORT;
Vue.prototype.$appDomain = process.env.VUE_APP_DOMAIN;

/*
let env = 'local' // local,uat,prod
let epURL = '';


if (env === 'local'){
  epURL = 'http://localhost:8000/';
}else if(env === 'uat'){
  epURL = 'https://api.ml.2andthree.com/';
}else if(env === 'prod'){
  epURL = 'https://api.mangolola.com/';
}else{
  epURL = 'https://api.mangolola.com/';
}
*/

axios.defaults.baseURL = process.env.VUE_APP_API;


Vue.prototype.$eventBus = new Vue();

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce)

// Slug
import Slug from 'slug';
Slug.defaults.mode = 'rfc3986';

// Mouseflow
//import VueMouseflow from 'vue-mouseflow';
//Vue.use(VueMouseflow, { tracking_key: 'fc5a358c-5fa7-4582-9b31-ec8d4edd2072' });

// PUSHER

/*
let env = 'local' // local prod
let epURL = '';
let api_key = '';

if (env == 'local'){
   epURL = 'http://localhost:8000/';
   api_key = 'abd735c75630daabdf3b';// Pusher
}else{
   epURL = 'https://api.ml.2andthree.com/';
   api_key = '5b75f4885c2dfa6ac86f';// Pusher
}

import Pusher from 'pusher-js';
Pusher.logToConsole = true;

import VuePusher from 'vue-pusher';
const AUTH_ENDPOINT = epURL.concat('broadcasting/auth');

Vue.use(VuePusher, {
  api_key: api_key,
  options: {
    cluster: 'eu',
    encrypted: true,
    forceTLS: true,
    authEndpoint: AUTH_ENDPOINT,
    authTransport: 'jsonp', // jsonp ajax
  }
});
////
*/

/// Image CROP
import VueRx from 'vue-rx'
import VuejsClipper from 'vuejs-clipper'
// install vue-rx
Vue.use(VueRx)
// install vuejs-clipper
Vue.use(VuejsClipper)

import firebaseMessaging from './plugins/firebase';
Vue.prototype.$messaging = firebaseMessaging

store.dispatch('auth/me').then(() => {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    beforeCreate() { this.$store.commit('filter/initFilterStore'); },
    render: h => h(App)
  }).$mount('#app')
})

/*

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

*/

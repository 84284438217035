
import Cookies from 'js-cookie'
import * as types from '../mutation-types'
import axios from 'axios';

/*
export default {
  namespaced: true,
}
*/
export const state = {
    authenticated: false,
    user: null,
    token: Cookies.get('token')
}

export const getters = {
    authenticated (state) {
      return state.authenticated
    },
    user (state) {
      return state.user
    },
    check (state) {
      return (state.user !== null)
    },
    guest (state) {
        return (state.user !== null && state.user.guest === true)
    },
    ghost (state) {
        return (state.user !== null && state.user.ghost === true)
    },
    guestGhost (state) {
        return (state.user !== null && state.user.guest === true && state.user.ghost === true)
    },
    token (state) {
      return state.token
    },
}

export const mutations = {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
      //console.log("SET_AUTHENTICATED mutation: " + state.authenticated);
    },

    SET_USER (state, value) {
      state.user = value
      //console.log("SET_USER mutation: " + state.user);
    },
    [types.SAVE_TOKEN] (state, { token, remember }) {
      state.token = token
      Cookies.set('token', token, { expires: remember ? 365 : null })
      //console.log("SAVE_TOKEN mutation");
    },
  
    [types.FETCH_USER_SUCCESS] (state, { user }) {
      state.user = user
    },
  
    [types.FETCH_USER_FAILURE] (state) {
      state.token = null
      Cookies.remove('token')
      //console.log("FETCH_USER_FAILURE");

    },
  
    [types.LOGOUT] (state) {
      state.user = null
      state.token = null
      Cookies.remove('token')
      //console.log("LOGOUT");
    },
  
    [types.UPDATE_USER] (state, { user }) {
      state.user = user
      //console.log("UPDATE_USER");
    }
}

export const actions = {
    saveToken ({ commit }, payload) {
      commit(types.SAVE_TOKEN, payload)
    },
    async fetchUser ({ commit }) {
      try {
        const { data } = await axios.get('/api/user')
  
        commit(types.FETCH_USER_SUCCESS, { user: data.data })
      } catch (e) {
        commit(types.FETCH_USER_FAILURE)
      }
    },
    updateUser ({ commit }, payload) {
      commit(types.UPDATE_USER, payload)
    },
    
    async signIn ({ dispatch }, credentials) {
      
       await axios.get('sanctum/csrf-cookie')
       await axios.post('/api/login', credentials)
       return dispatch('me')
    },

    async signOut ({dispatch}) {

      await axios.post('/api/logout')
      return dispatch('me')

    },

    me ({ commit }) {
      return axios.get('/api/user').then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data.data)
        console.log("Add Auth values");
      }).catch(() => {
        console.log("Remove Auth values");
        commit(types.LOGOUT)
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
    }
    
}

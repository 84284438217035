<template>
  <v-app id="app">
    <AppLayout>
      <v-main>
        <transition>
          <keep-alive>
            <router-view :key="$route.fullPath"></router-view>
          </keep-alive>
        </transition>
      </v-main>
    </AppLayout>
  </v-app>
</template>

<style lang="scss">
@import './assets/sass/app.scss';
</style>
<style>

.break-inside-avoid {
  break-inside: avoid;
}

.column-count-2 {
  column-count: 2;
  column-gap: 1.5rem;
}

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
}

.no-toolbar-p .v-toolbar__content {
  padding: 0;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.title {
  font-size: 85px;
}

#nprogress .spinner-icon {
  border-top-color: #d2503c !important;
  border-left-color: #d2503c !important;
}
</style>

<script>
import AppLayout from '@/layouts/AppLayout'

export default {
  components: {
    AppLayout,
  },
  metaInfo: {
    title: 'Mango & Lola: The Ultimate Wedding Planning Software',
    titleTemplate: '%s | Mango & Lola is your one-stop-shop for finding the best wedding vendors and services. Browse through our list of top-rated vendors and book them directly on our platform.'
  }
}
</script>
